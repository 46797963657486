const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  activeProduct: [],
  // change_PasswordData:[],
  activeModel:[],
  categoryWiseProduct : []
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_PRODUCT':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ALL_ACITVE_PRODUCT':
      return {
        ...state,
        activeProduct: action.data,
      }

    case 'CREATE_PRODUCT':
      return { ...state }
    case 'UPDATE_PRODUCT':
      return { ...state }
    case 'DELETE_PRODUCT':
      return { ...state }

    case 'GET_DATA_ALL_ACITVE_MODEL':
      return {
        ...state,
        activeModel: action.data,
      }

      case 'GET_CATEGORY_WISE_PRODUCT':
      return {
        ...state,
        categoryWiseProduct: action.data,
      }


    default:
      return { ...state }
  }
}
export default productReducer
