const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const userData = localStorage.getItem("userData");
const userType = JSON.parse(userData)?.user_type;
const developerLogin = JSON.parse(userData)?.developer_login || false;

const MenuItems = [
  {
    menu_title: "Dashboard",
    path: "/app/dashboard",
    icon: "dashboard",
    category: "general",
    isMenuOpen: false,
    menu: "dashboard",
    fullPageMenu: false,
    desc: true,
    content: "",
    child_routes: null,
    // permission: true,
    permission:
      permission && permission.Master !== undefined
        ? permission && permission.Master.includes("user")
        : false,
  },
  {
    menu_title: "Master",
    path: "/app/master/",
    icon: "folder",
    category: "modules",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Master !== undefined ? permission.Master.length > 0 : false,
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "Internal User Master",
        icon: "person",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("user")
            : false,
      },
      {
        path: "/app/master/designation",
        menu_title: "Designation Master",
        icon: "work_outline",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("designation_master")
            : false,
      },
      {
        path: "/app/master/department",
        menu_title: "Department Master",
        icon: "business",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("department_master")
            : false,
      },
      {
        path: "/app/master/city",
        menu_title: "City Master",
        icon: "location_city",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("city_master")
            : false,
      },
      // {
      //   path: "/app/master/product",
      //   menu_title: "Product Master",
      //   icon: "local_grocery_store",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("product_master")
      //       : false,
      // },
      // {
      //   path: "/app/master/industry",
      //   menu_title: "Industry Master",
      //   icon: "factory",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("industry_master")
      //       : false,
      // },
      {
        path: "/app/master/leadsource",
        menu_title: "Lead Source Master",
        icon: "grade",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("lead_source_master")
            : false,
      },
      {
        path: "/app/master/remark",
        menu_title: "Follow Up Remark Master",
        icon: "follow_the_signs",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("remark_master")
            : false,
      },
      {
        path: "/app/master/whatsappdraft",
        menu_title: "WhatsApp Draft Master",
        icon: "chat",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("wa_draft_master")
            : false,
      },
      {
        path: "/app/master/whatsapp-draft-category",
        menu_title: "WhatsApp Draft Category Master",
        icon: "category",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("wa_draft_category_master")
            : false,
      },
      // {
      //   path: "/app/master/templatetrigger",
      //   menu_title: "Template Trigger Master",
      //   icon: "send_time_extension",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission &&
      //       permission.Master.includes("template_trigger_master")
      //       : false,
      // },
      // {
      //   path: "/app/master/company",
      //   menu_title: "Company Master",
      //   icon: "business",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   // permission: true,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission &&
      //       permission.Master.includes("company_master") &&
      //       userType === "Super Admin"
      //       : false,
      // },
      // {
      //   path: "/app/master/meta-fields",
      //   menu_title: "MetaFields Master",
      //   icon: "tune",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("metafields_master")
      //       : false,
      // },
      {
        path: "/app/master/setting",
        menu_title: "Setting Master",
        icon: "phonelink_setup",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("setting_master") &&
            userType === "Super Admin"
            : false,
      },
      {
        path: "/app/master/branch",
        menu_title: "Branch Master",
        icon: "add_business",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            (permission.Master.includes("branch_master") ||
              userType === "Super Admin")
            : false,
      },
      {
        path: "/app/master/developer",
        menu_title: "Developer Master",
        icon: "engineering",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("developer_master")
            : false,
      },
      {
        path: "/app/master/project",
        menu_title: "Project Master",
        icon: "business",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("project_master")
            : false,
      },
      
      {
        path: "/app/master/associate",
        menu_title: "CP/Associate Master",
        icon: "co_present",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("associate_master")
            : false,
      },
      {
        path: "/app/master/bank",
        menu_title: "Bank Master",
        icon: "account_balance",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("bank_master")
            : false,
      },
      {
        path: "/app/master/bank-branch",
        menu_title: "Bank Branch Master",
        icon: "home_work",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("bank_branch_master")
            : false,
      },
    ],
  },
  {
    menu_title: "Product",
    path: "/app/master/",
    icon: "home_work",
    category: "products",
    isMenuOpen: false,
    menu: "product",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: permission?.ProductModules !== undefined ? permission?.ProductModules?.length > 0 : false,
    

    child_routes: [
      {
        path: "/app/master/productCategory",
        menu_title: "Product Category Master",
        icon: "shopping_cart",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ProductModules !== undefined
            ? permission && permission.ProductModules.includes("product_category_master")
            : false,
      },
      {
        path: "/app/master/productMaster",
        menu_title: "Product Master",
        icon: "add_shopping_cart",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ProductModules !== undefined
            ? permission && permission.ProductModules.includes("product_master")
            : false,
      },
      {
        path: "/app/master/bank-wise-revenue",
        menu_title: "Bank Wise Revenue",
        icon: "account_balance",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ProductModules !== undefined
            ? permission && permission.ProductModules.includes("bank_wise_revenue")
            : false,
      },
    ],
  },
  {
    menu_title: "Leads",
    path: "/app/leads",
    icon: "group_add",
    category: "components",
    isMenuOpen: false,
    menu: "leads",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Leads !== undefined ? permission.Leads.length > 0 : false,
    child_routes: [
      {
        path: "/app/leads/leadsform",
        menu_title: "Leads Form",
        icon: "manage_accounts",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Leads !== undefined
            ? permission && permission.Leads.includes("leads_form")
            : false,
      },
      {
        path: "/app/leads/leads-import",
        menu_title: "Leads Import",
        icon: "download_for_offline",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Leads !== undefined
            ? permission && permission.Leads.includes("leads_import")
            : false,
        // permission: true,
      },
      {
        path: "/app/leads/generate-invoices",
        menu_title: "Generate Invoices",
        icon: "receipt_long",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Leads !== undefined
            ? permission && permission.Leads.includes("generate_invoices")
            : false,
        // permission: true,
      },
    ],
  },
  {
    menu_title: "Reports",
    path: "/app/reports",
    icon: "assignment",
    category: "reports",
    isMenuOpen: false,
    menu: "reports",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Reports !== undefined ? permission.Reports.length > 0 : false,
    child_routes: [
      {
        path: "/app/reports/calling-performance-report",
        menu_title: "Calling Performance Report",
        icon: "smartphone",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission &&
            permission.Reports.includes("calling_performance_report")
            : false,
      },
      {
        path: "/app/reports/calling-history-report",
        menu_title: "Calling History Report",
        icon: "history",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission &&
            permission.Reports.includes("calling_history_report")
            : false,
      },
      {
        path: "/app/reports/leads-report",
        menu_title: "Leads Report",
        icon: "assignment_ind",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission && permission.Reports.includes("leads_report")
            : false,
      },
      {
        path: "/app/reports/user-call-log-report",
        menu_title: "User Call Log Report",
        icon: "call",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission && permission.Reports.includes("user_call_log_report")
            : false,
      },
      {
        path: "/app/reports/developer-leads-report",
        menu_title: developerLogin ? "Leads Report" : "Developer Leads Report" ,
        icon: "engineering",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission && permission.Reports.includes("developer_leads_report")
            : false,
      },
      {
        path: "/app/reports/payout-report",
        menu_title: "Payout Report",
        icon: "currency_rupee",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission && permission.Reports.includes("payout_report")
            : false,
      },
      {
        path: "/app/reports/invoice-report",
        menu_title: "Invoice Report",
        icon: "receipt_long",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission && permission.Reports.includes("invoice_report")
            : false,
      },
      {
        path: "/app/reports/disbursement-report",
        menu_title: "Disbursement Report",
        icon: "attach_money",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission && permission.Reports.includes("disbursement_report")
            : false,
      },
      {
        path: "/app/reports/developer-invoice-report",
        menu_title: "Developer Invoice Report" ,
        icon: "engineering",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Reports !== undefined
            ? permission && permission.Reports.includes("developer_invoice_report")
            : false,
      },
    ],
  },
  // ----------------------------Attendance------------------
  {
    menu_title: "Attendance",
    path: "/app/attendance",
    icon: "assignment",
    category: "attendance",
    isMenuOpen: false,
    menu: "attendance",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Attendance !== undefined ? permission?.Attendance.length > 0 : false,
    child_routes: [
      {
        path: "/app/attendance/LeaveApprovalMaster",
        menu_title: "Leave Approval",
        icon: "rule",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("leave_approval")
          : false,
      },

      // {
      //   path: "/app/attendance/WeeklyOffRosterMaster",
      //   menu_title: "Week Off Roster",
      //   icon: "history_toggle_off",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: permission && permission.Attendance !== undefined
      //     ? permission && permission.Attendance.includes("weekly_off_roster")
      //     : false,
      // },

      {
        path: "/app/attendance/AttendanceReport",
        menu_title: "Daily Report",
        icon: "article",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("daily_report")
          : false,
      },
      {
        path: "/app/attendance/monthlyAttendanceReport",
        menu_title: "Monthly Report",
        icon: "assessment",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("monthly_report")
          : false,
      },
      {
        path: "/app/attendance/AttendanceSummaryReport",
        menu_title: "Summary Report",
        icon: "summarize",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("summery_report")
          : false,
      },
      {
        path: "/app/attendance/ShiftMaster",
        menu_title: "Shift",
        icon: "work_history",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("shift")
          : false,
      },
      {
        path: "/app/attendance/WeeklyOffMaster",
        menu_title: "Weekly Off Master",
        icon: "date_range",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("weekly_off")
          : false,
      },
      {
        path: "/app/attendance/HoliydayMaster",
        menu_title: "Holiday",
        icon: "beach_access",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("holiday")
          : false,
      },

    ],
  },
  // ---------------------- Live Tracking ------------------//
  {
    menu_title: "LiveTracking",
    path: "/app/tracking",
    icon: "assignment",
    category: "liveTracking",
    isMenuOpen: false,
    menu: "liveTracking",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.LiveTracking !== undefined ? permission.LiveTracking.length > 0 : false,
    child_routes: [
      {
        path: "/app/tracking/location-tracking",
        menu_title: "Location Tracking",
        icon: "navigation",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.LiveTracking !== undefined
            ? permission &&
            permission.LiveTracking.includes("location_tracking")
            : false,
      },
      {
        path: "/app/tracking/user-tracking",
        menu_title: "User Tracking",
        icon: "person_pin_circle",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.LiveTracking !== undefined
            ? permission &&
            permission.LiveTracking.includes("user_tracking")
            : false,
      },


    ],
  },
  //------------------------Setting-------------------------//
  {
    menu_title: "Setting",
    path: "/app/setting",
    icon: "assignment",
    category: "setting",
    isMenuOpen: false,
    menu: "setting",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Setting !== undefined ? permission?.Setting.length > 0 : false,
    child_routes: [
      {
        path: "/app/setting/setting",
        menu_title: "Setting Master",
        icon: "phonelink_setup",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Setting !== undefined
            ? permission && permission.Setting.includes("setting_master")
            : false,
      },
      {
        path: "/app/setting/hr-setting",
        menu_title: "HR Settings",
        icon: "manage_accounts",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Setting !== undefined
            ? permission && permission.Setting.includes("hr_setting")
            : false,
      },


    ],
  },
];

export default MenuItems;
