/**
 * Code splitting Components
 * AsyncComponents
 */
import React from "react";
import Loadable from "react-loadable";
import { HulkPageLoader } from "components/GlobalComponents";

//  Dashboard 1
const AsyncDashboardComponent = Loadable({
  loader: () => import("views/Dashboard"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Masters
const AsyncUserMasterComponent = Loadable({
  loader: () => import("views/Master/UserMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDesignationMasterComponent = Loadable({
  loader: () => import("views/Master/DesignationMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDepartmentMasterComponent = Loadable({
  loader: () => import("views/Master/DepartmentMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCityMasterComponent = Loadable({
  loader: () => import("views/Master/CityMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncIndustryMasterComponent = Loadable({
  loader: () => import("views/Master/IndustryMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncProductMasterComponent = Loadable({
  loader: () => import("views/Master/ProductMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncWaDraftMasterComponent = Loadable({
  loader: () => import("views/Master/WaDraftMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncTemplateTriggerMasterComponent = Loadable({
  loader: () => import("views/Master/TemplateTriggerMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncSettingMasterComponent = Loadable({
  loader: () => import("views/Master/SettingMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncRemarkMasterComponent = Loadable({
  loader: () => import("views/Master/RemarkMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncWaDraftCategoryMasterComponent = Loadable({
  loader: () => import("views/Master/WaDraftCategoryMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncMetaFieldsMasterComponent = Loadable({
  loader: () => import("views/Master/MetaFieldsMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//Leads
const AsyncLeadSourceMasterComponent = Loadable({
  loader: () => import("views/Master/LeadSourceMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncLeadsFormMasterComponent = Loadable({
  loader: () => import("views/Master/LeadsFormMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncLeadsImportComponent = Loadable({
  loader: () => import("views/Master/LeadsImport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCompanyMasterComponent = Loadable({
  loader: () => import("views/Master/CompanyMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Error Page 404
const AsyncErrorPage404Component = Loadable({
  loader: () => import("views/Error/404"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
  loader: () => import("views/Error/403"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
  loader: () => import("views/Error/500"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
  loader: () => import("views/SampleForm"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncLeadsReportsComponent = Loadable({
  loader: () => import("views/Reports/LeadsReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCallingPerformanceReportComponent = Loadable({
  loader: () => import("views/Reports/CallingPerformanceReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCallingHistoryReportComponent = Loadable({
  loader: () => import("views/Reports/CallingHistoryReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//-------------User Call log report---------------------//
const AsyncUserCallLogReportComponent = Loadable({
  loader: () => import("views/Reports/UserCallLogReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// ------------------Branch Master--------------------
const AsyncBranchMasterComponent = Loadable({
  loader: () => import("views/Master/BranchMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});


// ------------------Shift Master--------------------
const AsyncShiftMasterComponent = Loadable({
  loader: () => import("views/Attendance/ShiftMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//------------------weekly off Master-----------------
const AsyncWeeklyOffMasterComponent = Loadable({
  loader: () => import("views/Attendance/WeeklyOffMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//------------------Holiday Master-----------------
const AsyncHolidayMasterComponent = Loadable({
  loader: () => import("views/Attendance/HoliydayMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//-------------------Attendance Report ------------
const AsyncAttendanceReportMasterComponent = Loadable({
  loader: () => import("views/Attendance/AttendanceReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//-------------------Monthly Attendance Report ------------
const AsyncMonthlyAttendanceReportMasterComponent = Loadable({
  loader: () => import("views/Attendance/MonthlyAttendanceReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});


//--------------- Attendance Summary Report--------------//
const AsyncAttendanceSummaryReportComponent = Loadable({
  loader: () => import("views/Attendance/AttendanceSummaryReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

//---------------HR setting Master----------------------//
const AsyncHrSettingMasterComponent = Loadable({
  loader: () => import("views/Master/HrSettingMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

//------------Week Off Roster---------------/
// const AsyncWeekOffRosterMasterComponent = Loadable({
//   loader: () => import("views/Attendance/WeeklyOffRoster/"),
//   loading: () => <HulkPageLoader />,
//   delay: 3000,
// })

//------------Leave Approval------------------//
const AsyncLeaveApprovalMasterComponent = Loadable({
  loader: () => import("views/Attendance/LeaveApproval/"),
  loading: () => < HulkPageLoader />,
  dalay: 3000,
})


// ------------ Live Tracking ---------------//
const AsyncLocationTrackingComponent = Loadable({
  loader: () => import("views/LiveTracking/locationTracking/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncUserTrackingComponent = Loadable({
  loader: () => import("views/LiveTracking/userTracking/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDeveloperMasterComponent = Loadable({
  loader: () => import("views/Master/DeveloperMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncProjectMasterComponent = Loadable({
  loader: () => import("views/Master/ProjectMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsyncProductCategoryMaster = Loadable({
  loader: () => import("views/Master/ProductCategoryMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncProductMaster = Loadable({
  loader: () => import("views/Master/ProductNewMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});


const AsyncAssociateMasterComponent = Loadable({
  loader: () => import("views/Master/AssociateMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncBankMasterComponent = Loadable({
  loader: () => import("views/Master/BankMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncBankBranchMasterComponent = Loadable({
  loader: () => import("views/Master/BankBranchMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDeveloperLeadsReportsComponent = Loadable({
  loader: () => import("views/Reports/DeveloperLeadsReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncBankWiseRevenueMasterComponent = Loadable({
  loader: () => import("views/Master/BankWiseRevenue/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncGenerateInvoicesComponent = Loadable({
  loader: () => import("views/Master/GenerateInvoices/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncPayoutReportsComponent = Loadable({
  loader: () => import("views/Reports/PayoutReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncInvoiceReportsComponent = Loadable({
  loader: () => import("views/Reports/InvoiceReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDisbursementReportsComponent = Loadable({
  loader: () => import("views/Reports/DisbursementReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDeveloperInvoiceReportsComponent = Loadable({
  loader: () => import("views/Reports/DeveloperInvoiceReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

export {
  AsyncDashboardComponent,
  AsyncUserMasterComponent,
  AsyncErrorPage404Component,
  AsyncErrorPage500Component,
  AsyncErrorPage403Component,
  AsyncSampleFormComponent,
  AsyncDesignationMasterComponent,
  AsyncDepartmentMasterComponent,
  AsyncCityMasterComponent,
  AsyncIndustryMasterComponent,
  AsyncProductMasterComponent,
  AsyncWaDraftMasterComponent,
  AsyncLeadSourceMasterComponent,
  AsyncLeadsFormMasterComponent,
  AsyncSettingMasterComponent,
  AsyncTemplateTriggerMasterComponent,
  AsyncLeadsReportsComponent,
  AsyncLeadsImportComponent,
  AsyncCallingPerformanceReportComponent,
  AsyncCallingHistoryReportComponent,
  AsyncRemarkMasterComponent,
  AsyncWaDraftCategoryMasterComponent,
  AsyncCompanyMasterComponent,
  AsyncMetaFieldsMasterComponent,
  AsyncUserCallLogReportComponent,
  AsyncBranchMasterComponent,
  AsyncShiftMasterComponent,
  AsyncWeeklyOffMasterComponent,
  AsyncHolidayMasterComponent,
  AsyncAttendanceReportMasterComponent,
  AsyncMonthlyAttendanceReportMasterComponent,
  AsyncAttendanceSummaryReportComponent,
  AsyncHrSettingMasterComponent,
  // AsyncWeekOffRosterMasterComponent,
  AsyncLeaveApprovalMasterComponent,
  AsyncLocationTrackingComponent,
  AsyncUserTrackingComponent,
  AsyncDeveloperMasterComponent,
  AsyncProjectMasterComponent,
  AsyncProductCategoryMaster,
  AsyncProductMaster,
  AsyncAssociateMasterComponent,
  AsyncBankMasterComponent,
  AsyncBankBranchMasterComponent,
  AsyncDeveloperLeadsReportsComponent,
  AsyncBankWiseRevenueMasterComponent,
  AsyncGenerateInvoicesComponent,
  AsyncPayoutReportsComponent,
  AsyncInvoiceReportsComponent,
  AsyncDisbursementReportsComponent,
  AsyncDeveloperInvoiceReportsComponent,
};
