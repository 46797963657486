const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  allActiveBank: [],
};

const generateInvoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACTIVE_INVOICES":
      return {
        ...state,
        allActiveBank: action.data,
      };
    case "CREATE_INVOICES":
      return { ...state };
    case "UPDATE_INVOICES":
      return { ...state };
    default:
      return { ...state };
  }
};
export default generateInvoicesReducer;
